import * as dat from 'dat.gui'
import { gsap } from 'gsap'
import * as THREE from 'three'
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js'
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js'
import './style.css'

//ANIMAZIONE INTRO
if ($(window).width() < 600) {
    var tl = gsap.timeline();
tl.to(".ciao",{ opacity:1, delay:0.3, duration:1.5});
tl.to(".ciao",{ opacity:0, delay:0.5, display:"none", duration:1});
tl.to(".benvenuto",{ opacity:1, duration:1.5});
tl.to(".benvenuto",{ opacity:0, delay:0.7, display:"none", duration:1});
tl.to(".esplora",{ opacity:1, duration:1.5});
tl.to(".esplora",{ opacity:0, delay:1, display:"none", duration:1});
tl.to(".scritte-loading",{ borderRadius: 30, height:35, width:100, top: "50%", left: "30%", duration:0.5});
tl.to(".scritte-loading",{left: "50%", x:"-50%",y:"-50%", top: "93%",duration:0.3});
tl.to(".insta",{ opacity:1, duration:0.1});
tl.to(".insta",{ transform: "translate(-270%, -50%)", duration:1});
tl.to(".scritte-loading",{backgroundColor: "rgba(255, 255, 255, 0.50)",duration:0.5});

var fb= gsap.timeline();
fb.to(".fb",{opacity:1, delay:10.8, duration:0.1});
fb.to(".fb",{ transform: "translate(-390%, -50%)", opacity:1, duration:1});

var xll= gsap.timeline();
xll.to(".mail",{opacity:1, delay:10.8, duration:0.1});
xll.to(".mail",{ transform: "translate(170%, -50%)", opacity:1, duration:1});

var xlll= gsap.timeline();
xlll.to(".cell",{opacity:1, delay:10.8, duration:0.1});
xlll.to(".cell",{ transform: "translate(290%, -50%)", opacity:1, duration:1});

gsap.to(".esplora-fine",{opacity:1, delay:10.8, duration:0.5});
 }
 else {
var tll = gsap.timeline();
tll.to(".ciao",{ opacity:1, delay:0.3, duration:1.5});
tll.to(".ciao",{ opacity:0, delay:0.5, display:"none", duration:1});
tll.to(".benvenuto",{ opacity:1, duration:1.5});
tll.to(".benvenuto",{ opacity:0, delay:0.7, display:"none", duration:1});
tll.to(".esplora",{ opacity:1, duration:1.5});
tll.to(".esplora",{ opacity:0, delay:1, display:"none", duration:1});
tll.to(".scritte-loading",{ borderRadius: 30, height:50, width:150, top: "50%", left: "45%", duration:0.5});
tll.to(".scritte-loading",{left: "50%", x:"-50%",y:"-50%", top: "93%",duration:0.3});
tll.to(".insta",{ opacity:1, duration:0.1});
tll.to(".insta",{ transform: "translate(-270%, -50%)", duration:1});
tll.to(".scritte-loading",{backgroundColor: "rgba(255, 255, 255, 0.50)",duration:0.5});

var fbg= gsap.timeline();
fbg.to(".fb",{opacity:1, delay:10.8, duration:0.1});
fbg.to(".fb",{ transform: "translate(-390%, -50%)", opacity:1, duration:1});

var xll= gsap.timeline();
xll.to(".mail",{opacity:1, delay:10.8, duration:0.1});
xll.to(".mail",{ transform: "translate(170%, -50%)", opacity:1, duration:1});

var xlll= gsap.timeline();
xlll.to(".cell",{opacity:1, delay:10.8, duration:0.1});
xlll.to(".cell",{ transform: "translate(290%, -50%)", opacity:1, duration:1});

gsap.to(".esplora-fine",{opacity:1, delay:10.8, duration:0.5});
}


/**
 * Base
 */
// Debug
/* const gui = new dat.GUI({
    width: 400
}) */

// Canvas
const canvas = document.querySelector('canvas.webgl')

// Scene
const scene = new THREE.Scene()
scene.background = new THREE.Color(0xE0BB53);



/**
 * Loaders
 */
// Texture loader
const textureLoader = new THREE.TextureLoader()

let gltfLoader = new GLTFLoader()

/**
 * Textures
 */
const bakedTexture = textureLoader.load('indexbaked.jpg')
bakedTexture.flipY = false
//renderer per colori realistici
bakedTexture.encoding = THREE.sRGBEncoding

/**
 * Materials
 */
// Baked material
const bakedMaterial = new THREE.MeshBasicMaterial({ map: bakedTexture })


/**
 * Model
 */
gltfLoader.load(
    'Index.glb',
    (gltf) => {
        gltf.scene.traverse((child) => {
            gltf.scene.position.set(0,-0.3,0)
            child.material = bakedMaterial
        })
        scene.add(gltf.scene)
    }
)


/**
 * Sizes
 */
const sizes = {
    width: window.innerWidth,
    height: window.innerHeight
}

window.addEventListener('resize', () => {
    // Update sizes
    sizes.width = window.innerWidth
    sizes.height = window.innerHeight

    // Update camera
    camera.aspect = sizes.width / sizes.height
    camera.updateProjectionMatrix()

    // Update renderer
    renderer.setSize(sizes.width, sizes.height)
    renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))
})


/**
 * Camera
 */
// Base camera
const camera = new THREE.PerspectiveCamera(85, sizes.width / sizes.height, 0.1, 100)
camera.position.set(0, 0, 3)
camera.rotation.set(0, 0, 0)
camera.lookAt(scene.position)
scene.add(camera)


// Controls
var controls = new OrbitControls(camera, canvas)
controls.target.x = 0;
controls.target.y = 0.5;
controls.target.z = 0;
controls.enableDamping = true
controls.enablePan = false
controls.minDistance = 2.5;
controls.maxDistance = 5;
controls.minZoom = 1;
controls.maxZoom = 4;
controls.minPolarAngle = 1; // radians
controls.maxPolarAngle = 1.5; // radians 
controls.minAzimuthAngle = - 1; // radians
controls.maxAzimuthAngle = 1; // radians
controls.zoomSpeed = 0.3;
controls.rotateSpeed = 0.5;

/**
 * Renderer
 */
const renderer = new THREE.WebGLRenderer({
    canvas: canvas,
    antialias: true
})
renderer.setSize(sizes.width, sizes.height)
renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))
//renderer per colori realistici
renderer.outputEncoding = THREE.sRGBEncoding



/**
 * Animate
 */


const tick = () => {


    // Update controls
    controls.update()

    // Render
    renderer.render(scene, camera)

    // Call tick again on the next frame
    window.requestAnimationFrame(tick)
}

tick()